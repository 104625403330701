<!-- <span class="width"></span> -->

<!-- <header-share></header-share> -->

<main id="main">
  <!-- <sticky-button></sticky-button> -->
  <!-- <section [ngClass]="{ active: showBanner }" class="top-banner">
    <div class="banner-swiper">
      <div class="btn-box">
        <button (click)="close()" type="button" class="btn-close">
          <span class="blind">닫기</span>
        </button>
      </div>
      <div class="swiper home-banner-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide banner-box01"></div>
          <div class="swiper-slide banner-box02"></div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <button type="button" class="btn-more">
      <span class="blind">바우처 사업 문의하기</span>
    </button>
  </section> -->

  <!-- <section [ngClass]="{ active: showBanner }"
    class="top-banner">
    <div class="banner-wrap">
      <div class="swiper home-banner-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide banner-box01">
            <div class="btn-area">
              <button (click)="close()"
                type="button"
                class="btn-close">
                <span class="blind">닫기</span>
              </button>
            </div>
          </div>
          <div class="swiper-slide banner-box02">
            <div class="btn-area">
              <button (click)="close()"
                type="button"
                class="btn-close">
                <span class="blind">닫기</span>
              </button>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>

      <button (click)="goInquiry()"
        type="button"
        class="btn-more">
        <span class="blind">바우처 사업 문의하기</span>
      </button>
    </div>
  </section> -->
  <section class="main-banner real-main">
    <div class="main-point">
      <div class="title_box">
        <img loading="lazy"
          src="assets/imgs/mainImg/home-mainbanner-center.png"
          alt="백그라운드 이미지"
          class="back-img-center" />
        <!-- <h2 class="front-title">
          <div>
            <p><strong>스타트업</strong>을 위한</p>
            <p><em>완벽한</em> <strong> 앱 개발, 마케팅</strong> 파트너</p>
          </div>
        </h2> -->
        <h2 class="back-title">
          <div>
            <p class="main-title">새로운 시작은 스타트앱과 함께</p>
            <p class="sub-title">
              경험 많은 전문 인원이 하나의 팀으로 <br class="mo-show" />
              도와드립니다.
            </p>
            <button (click)="goInquiry()"
              type="button"
              class="inquiry-btn">
              무료 견적 신청
            </button>
          </div>
        </h2>
      </div>
      <!-- <article id="artiText">
        <p>우리는 당신의 시간과 돈의 가치를 잘 알고 있는 사람들 입니다.</p>

        <p>앱의 성공적인 출시를 꿈꾸는 모든 사람들을 위해</p>

        <p>기획, 디자인, 개발, 마케팅 이 모든 것을 체계적으로</p>
      </article> -->

      <img loading="lazy"
        src="assets/imgs/mainImg/home-mainbanner1.png"
        alt="백그라운드 이미지"
        class="back-img1" />
      <img loading="lazy"
        src="assets/imgs/mainImg/home-mainbanner2.png"
        alt="백그라운드 이미지"
        class="back-img2" />
      <img loading="lazy"
        src="assets/imgs/mainImg/home-mainbanner3-1.png"
        alt="백그라운드 이미지"
        class="back-img3" />
      <img loading="lazy"
        src="assets/imgs/mainImg/home-mainbanner4.png"
        alt="백그라운드 이미지"
        class="back-img4" />
      <img loading="lazy"
        src="assets/imgs/mainImg/home-mainbanner5-1.png"
        alt="백그라운드 이미지"
        class="back-img5" />
      <img loading="lazy"
        src="assets/imgs/mainImg/home-mainbanner6.png"
        alt="백그라운드 이미지"
        class="back-img6" />
    </div>
  </section>

  <section class="partner-section">
    <div class="title-area">
      <h2>
        <span class="small">
          정부기관, 메가 브랜드,<br class="tab-only" />
          스타트업 등 <br class="pc-show" /></span>

        이미 많은 브랜드가<br class="tab-show" />
        스타트앱을<br class="mo-show" />
        이용하고 있어요
      </h2>
    </div>

    <div class="partners-marquee-wrap">
      <div class="partners-marquee partners-marquee1">
        <div class="marquee marquee1">
          <div class="marquee-conts">
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo01.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo01-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo02.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo02-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo03.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo03-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo04.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo04-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo05.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo05-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo06.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo06-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo07.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo07-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
          </div>
        </div>

        <!-- 연속으로 나올 동일한 내용 -->
        <div class="marquee marquee2">
          <div class="marquee-conts">
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo01.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo01-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo02.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo02-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo03.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo03-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo04.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo04-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo05.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo05-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo06.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo06-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo07.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo07-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="partners-marquee partners-marquee2">
        <div class="marquee reverse">
          <div class="marquee-conts">
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo08.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo08-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo09.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo09-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo10.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo10-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo11.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo11-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo12.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo12-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo13.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo13-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
          </div>
        </div>
        <div class="marquee marquee2 reverse">
          <div class="marquee-conts">
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo08.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo08-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo09.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo09-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo10.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo10-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo11.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo11-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo12.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo12-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo13.png"
                  alt=""
                  class="pc-img" />
                <img src="/assets/imgs/company-logo13-mo.png"
                  alt=""
                  class="mo-show" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="partners-marquee partners-marquee3">
        <div class="marquee">
          <div class="marquee-conts">
            <div class="logo-box">
              <img src="/assets/imgs/company-logo14.png"
                alt=""
                class="pc-img" />
              <img src="/assets/imgs/company-logo14-mo.png"
                alt=""
                class="mo-show" />
            </div>
            <div class="logo-box">
              <img src="/assets/imgs/company-logo15.png"
                alt=""
                class="pc-img" />
              <img src="/assets/imgs/company-logo15-mo.png"
                alt=""
                class="mo-show" />
            </div>
            <div class="logo-box">
              <img src="/assets/imgs/company-logo16.png"
                alt=""
                class="pc-img" />
              <img src="/assets/imgs/company-logo16-mo.png"
                alt=""
                class="mo-show" />
            </div>
            <div class="logo-box">
              <img src="/assets/imgs/company-logo17.png"
                alt=""
                class="pc-img" />
              <img src="/assets/imgs/company-logo17-mo.png"
                alt=""
                class="mo-show" />
            </div>
            <div class="logo-box">
              <img src="/assets/imgs/company-logo18.png"
                alt=""
                class="pc-img" />
              <img src="/assets/imgs/company-logo18-mo.png"
                alt=""
                class="mo-show" />
            </div>
            <div class="logo-box">
              <img src="/assets/imgs/company-logo19.png"
                alt=""
                class="pc-img" />
              <img src="/assets/imgs/company-logo19-mo.png"
                alt=""
                class="mo-show" />
            </div>
          </div>
        </div>
        <div class="marquee marquee2">
          <div class="marquee-conts">
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo14.png"
                  alt="" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo15.png"
                  alt="" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo16.png"
                  alt="" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo17.png"
                  alt="" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo18.png"
                  alt="" />
              </div>
            </div>
            <div class="logo-box">
              <div class="img-box">
                <img src="/assets/imgs/company-logo19.png"
                  alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="data-section">
    <div class="video-wrap">
      <div class="dim-box">
        <video src="/assets/imgs/main-data-video.mp4"
          loop
          autoplay
          muted
          playsinline
          preload="auto"
          oncanplay="this.muted=true"
          #myVideoElement></video>
      </div>

      <ul class="amount-list">
        <li>
          <p class="sort-name ani">
            <span class="icon inquiry"></span>개발 프로젝트 문의 건
          </p>
          <p class="number ani second">
            <span class="number-text">{{ inquiryNumber }}명</span><span class="plus"></span>
          </p>
        </li>
        <li>
          <p class="sort-name ani">
            <span class="icon project"></span>누적 프로젝트 수
          </p>
          <p class="number ani second">
            <span class="number-text">{{ projectNumber }}여개</span><span class="plus"></span>
          </p>
        </li>
        <li>
          <p class="sort-name ani">
            <span class="icon continue"></span>계약 연장 비율
          </p>
          <p class="number ani second">
            <span class="number-text">{{ continueNumber }}%</span>
          </p>
        </li>
      </ul>
    </div>
  </section>

  <section class="review-section">
    <div class="title-area">
      <h2>
        <span class="small">빠르게 성장하는 기업들은<br class="tab-only" />
          이미</span>
        <br class="mo-show" />
        스타트앱과<br class="pc-only" />
        성공적으로<br class="tab-show" />
        프로젝트를<br class="mo-show small" />
        완성했습니다.
      </h2>
    </div>

    <div class="review-marquee-wrap">
      <div class="review-marquee review-marquee1">
        <div class="marquee">
          <div class="marquee-conts">
            <div *ngFor="let item of reviews"
              class="logo-box">
              <div>
                <p class="title">
                  {{ item.title }}
                </p>
                <p class="desc">{{ item.desc }}</p>
              </div>

              <div class="user-box">
                <!-- <div class="img-box">
                  <img [src]="item.img" alt="" />
                </div> -->
                <p class="company-name">{{ item.companyName }}</p>
                <span>{{ item.name }} 님</span>
              </div>
            </div>
          </div>
        </div>
        <div class="marquee marquee2">
          <div class="marquee-conts">
            <div *ngFor="let item of reviews"
              class="logo-box">
              <div>
                <p class="title">
                  {{ item.title }}
                </p>
                <p class="desc">{{ item.desc }}</p>
              </div>

              <div class="user-box">
                <!-- <div class="img-box">
                  <img [src]="item.img" alt="" />
                </div> -->
                <p class="company-name">{{ item.companyName }}</p>
                <span>{{ item.name }} 님</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="reason-section"
    id="process">
    <div class="title-area">
      <h2 class="border">이 모든게 가능한 이유</h2>
    </div>

    <div class="reason-main">
      <!--1단-->
      <div class="img-area">
        <div class="img-box img1">
          <img loading="lazy"
            src="assets/imgs/home-snd1.png"
            alt="" />
        </div>
        <div class="img-box img2">
          <img loading="lazy"
            src="assets/imgs/home-snd2.png"
            alt="" />
        </div>
        <div class="img-box img3">
          <img loading="lazy"
            src="assets/imgs/home-snd1.png"
            alt="" />
        </div>
        <div class="img-box img4">
          <img loading="lazy"
            src="assets/imgs/home-snd2.png"
            alt="" />
        </div>
      </div>
      <!--2단-->
      <div class="txt-area">
        <article class="txt-box txt1">
          <div>
            <h3>UX/UI 완벽한 듀오</h3>
            <p>오직 사용자의 관점으로</p>
            <p>기능성, 신뢰성, 사용성, 즐거움을 모두 갖췄습니다.</p>
            <a (click)="goplan()">알아보기 > </a>
          </div>
        </article>
        <article class="txt-box txt2">
          <div>
            <h3>감각 있는<span class="break"></span> 비주얼 요소들</h3>
            <p>직관적이되, 화려함 또한 입혔습니다</p>
            <a (click)="godesign()">알아보기 > </a>
          </div>
        </article>
        <article class="txt-box txt3">
          <div>
            <h3>가장 빠르고,<span class="break"></span> 가장 정확한</h3>
            <p>단순한 효과 부터 복잡한 시스템 까지</p>
            <a (click)="godevelopment()">알아보기 > </a>
          </div>
        </article>
        <article class="txt-box txt4">
          <div>
            <h3>사람들을<span class="break"></span> 끌어 당기는 방법</h3>
            <p>우리는 사용자의 행동 패턴 분석을 통한</p>
            <p>핵심 전략을 알고 있습니다.</p>
            <a (click)="gomarketing()">알아보기 > </a>
          </div>
        </article>
      </div>

      <!--모바일일때-->
      <div class="m-txt-area">
        <article class="txt-box txt1">
          <div>
            <h3>UX/UI<br />완벽한 듀오</h3>
            <div>
              <p>
                오직 사용자의 관점으로<br />기능성, 신뢰성, 사용성, 즐거움을
                모두 갖췄습니다.
              </p>
              <a (click)="goplan()">알아보기 > </a>
            </div>
          </div>
        </article>
        <article class="txt-box txt2">
          <div>
            <h3>감각 있는<br />비주얼 요소들</h3>
            <div>
              <p>직관적이되, 화려함 또한 입혔습니다</p>
              <a (click)="godesign()">알아보기 > </a>
            </div>
          </div>
        </article>
        <article class="txt-box txt3">
          <div>
            <h3>
              가장 빠르고,<br />
              가장 정확한
            </h3>
            <div>
              <p>단순한 효과 부터 복잡한 시스템 까지</p>
              <a (click)="godevelopment()">알아보기 > </a>
            </div>
          </div>
        </article>
        <article class="txt-box txt4">
          <div>
            <h3>
              사람들을<br />
              끌어 당기는 방법
            </h3>
            <div>
              <p>
                우리는 사용자의 행동 패턴 분석을 통한<br />핵심 전략을 알고
                있습니다.
              </p>
              <a (click)="gomarketing()">알아보기 > </a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
  <section class="guide-section js-cursor"
    data-cursor="cursor__grab">
    <div class="guide-wrap">
      <div class="hidden-card"></div>
      <div class="title-area title-line2">
        <h2>성공을 위한 가이드</h2>
        <p class="sub_title">
          비즈니스 목표를 달성하고 고객을 만족시키는<span class="break"></span>
          웹 및 모바일 구축 및 체계적인 마케팅 스타터팩까지
        </p>
      </div>

      <div class="slide_wrap">
        <!--swiper 시작-->

        <div class="guide-swiper-wrap">
          <div class="swiper-container guide-swiper">
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
              <!-- Slides -->
              <div class="swiper-slide">
                <h3 class="ux-icon">시장조사 및 UX기획</h3>
                <p>
                  프로젝트의 시작점<br />
                  연구/분석을 통한 고객의 맞춘 니즈파악
                </p>
              </div>
              <div class="swiper-slide">
                <h3 class="ui-icon">UI 디자인</h3>
                <p>비주얼과 감정, 인식, 느낌을 기반한 사용자 중심의 디자인</p>
              </div>
              <div class="swiper-slide">
                <h3 class="pro-icon">프로그래밍</h3>
                <p>기본적인 기능부터 복잡한 시스템까지 Android, iOS 개발</p>
              </div>
              <div class="swiper-slide">
                <h3 class="qa-icon">QA</h3>
                <p>꼼꼼한 검수를 통한 원활한 앱 기능 배포</p>
              </div>
              <div class="swiper-slide">
                <h3 class="as-icon">유지보수</h3>
                <p>최신 업데이트 포함 안정적인 유지보수</p>
              </div>
              <div class="swiper-slide">
                <h3 class="mar-icon">마케팅</h3>
                <p>성공적인 비즈니스를 위한 최적의 전략 마케팅 컨설팅</p>
              </div>
            </div>
            <span class="swiper-button-prev"></span>
            <span class="swiper-button-next"></span>
          </div>
        </div>
      </div>

      <!--모바일 슬라이드 테스트///////////-->
      <div class="m-slide-wrap">
        <!--swiper 시작-->

        <div class="m-swiper-container">
          <span class="swiper-button-prev"></span>
          <span class="swiper-button-next"></span>

          <!-- Additional required wrapper -->
          <div class="swiper-wrapper">
            <!-- Slides -->
            <div class="swiper-slide">
              <h3 class="ux-icon">시장조사 및 UX기획</h3>
              <p>
                프로젝트의 시작점<br />
                연구/분석을 통한 고객의 맞춘 니즈파악
              </p>
            </div>
            <div class="swiper-slide">
              <h3 class="ui-icon">UI 디자인</h3>
              <p>비주얼과 감정, 인식, 느낌을 기반한 사용자 중심의 디자인</p>
            </div>
            <div class="swiper-slide">
              <h3 class="pro-icon">프로그래밍</h3>
              <p>기본적인 기능부터 복잡한 시스템까지 Android, iOS 개발</p>
            </div>
            <div class="swiper-slide">
              <h3 class="qa-icon">QA</h3>
              <p>꼼꼼한 검수를 통한 원활한 앱 기능 배포</p>
            </div>
            <div class="swiper-slide">
              <h3 class="as-icon">유지보수</h3>
              <p>최신 업데이트 포함 안정적인 유지보수</p>
            </div>
            <div class="swiper-slide">
              <h3 class="mar-icon">마케팅</h3>
              <p>성공적인 비즈니스를 위한 최적의 전략 마케팅 컨설팅</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="swiper-pagination"></span>
    <a (click)="goService()"
      class="more-text">우리에 대해 알아보기 ></a>
  </section>

  <section class="portfolio-wrap">
    <div class="cursor js-cursor-el"
      data-module="cursor"
      data-cursor="cursor__close">
      <div class="cursor__cursor">
        <div class="cursor__shape cursor__grab">
          <span>DRAG</span>
        </div>

        <svg width="134px"
          height="80px"
          viewBox="0 0 134 80"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="isdown"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            fill-opacity="0.8"
            class="cursor__shape cursor__click">
            <g id="web-copy"
              transform="translate(-1759.000000, -3387.000000)"
              fill="#000000">
              <circle id="Oval-Copy-3"
                cx="1826"
                cy="3427"
                r="40"></circle>
              <polygon id="Triangle"
                transform="translate(1765.000000, 3427.000000) rotate(-90.000000) translate(-1765.000000, -3427.000000) "
                points="1765 3421 1772 3433 1758 3433"></polygon>
              <polygon id="Triangle-Copy"
                transform="translate(1887.000000, 3427.000000) scale(-1, 1) rotate(-90.000000) translate(-1887.000000, -3427.000000) "
                points="1887 3421 1894 3433 1880 3433"></polygon>
            </g>
          </g>
        </svg>
      </div>
    </div>
    <div class="con-wrap">
      <!--포트폴리오 전체 웹 순서 잘못되어있음 추후 수정-->

      <h2>스타트앱과 함께한 프로젝트</h2>
      <a (click)="goPortfolio()">더 알아보기 > </a>

      <div class="pf-wrap portfolio-box"
        id="portfolioWrap">
        <h3 class="hidden">포트폴리오 리스트</h3>

        <article class="main-area">
          <div class="title-area">
            <h4>한라</h4>
            <p>
              우리는 한라 앱에서 제공하는 다양한 서비스를 사용자가 쉽고<br />
              편하게 사용할 수 있도록 최적화된 플로우와 화면을 구현하고<br />
              연구 개발 하였습니다.
            </p>
            <a (click)="goHanra()"> 프로젝트 자세히 보기 > </a>
          </div>
          <img loading="lazy"
            src="assets/imgs/portfolio-imgs/home-pf-main.png"
            alt="한라 앱 이미지"
            class="hanra-back" />
          <img loading="lazy"
            class="app-icon"
            src="assets/imgs/portfolio-imgs/pf-icon-hanra.png"
            alt="한라 앱 아이콘" />
        </article>

        <div class="pf-list-area">
          <div class="list-box">
            <ul>
              <li *ngFor="let item of portfolio">
                <div class="img-box">
                  <a (click)="goPfDetail(item.id)"
                    class="link-top"
                    title="포트폴리오 자세히 보기"></a>
                  <img loading="lazy"
                    src="assets/imgs/portfolio-imgs/home-pf-mockup.png"
                    alt="" />
                  <div class="inner-box">
                    <img loading="lazy"
                      [src]="item.appImg[0]"
                      [alt]="item.title + '앱 이미지'" />
                  </div>
                </div>
                <div class="txt-box">
                  <h4>{{ item.title }}</h4>
                  <p>{{ item.subInfo }}</p>
                  <!-- <a  [routerLink]="/portfolio-detail/{{ item.url }}">자세히 보기 ></a> -->
                  <a>자세히 보기 ></a>
                </div>
              </li>
            </ul>

            <div class="app-icon-wrap">
              <img loading="lazy"
                class="app-icon"
                *ngFor="let item of appIcon"
                [src]="item.img"
                alt="앱 아이콘 이미지" />
            </div>
          </div>
        </div>
      </div>

      <!--
      <div class="portfolio-box">
        <div class="portfolio-card">
          <div class="img-wrap">
            <img loading="lazy" src="assets/imgs/portfol1.png" alt="" />
          </div>
          <h3>미션고</h3>
          <p>미션 참여를 통해 소통하는 앱</p>
        </div>
        <div class="portfolio-card">
          <div class="img-wrap">
            <img loading="lazy" src="assets/imgs/portfol2.png" alt="" />
          </div>
          <h3>선한이웃</h3>
          <p>어르신가족, 요양보호사, 요양시설 커뮤니티케어 협력 서비스</p>
        </div>
        <div class="portfolio-card">
          <div class="img-wrap">
            <img loading="lazy" src="assets/imgs/portfol3.png" alt="" />
          </div>
          <h3>웨이팅알리미</h3>
          <p>지도기반 맛집 대기번호 발급 앱</p>
        </div>
        <div class="portfolio-card">
          <div class="img-wrap">
            <img loading="lazy" src="assets/imgs/portfol4.png" alt="" />
          </div>
          <h3>커버유</h3>
          <p>미국 유학생 커뮤니티 앱</p>
        </div>
        <div class="portfolio-card">
          <div class="img-wrap">
            <img loading="lazy" src="assets/imgs/portfol5.png" alt="" />
          </div>
          <h3>세모남</h3>
          <p>남성 전용 뷰티앱. 세상 모든 남자 화장품</p>
        </div>
        <div class="portfolio-card">
          <div class="img-wrap">
            <img loading="lazy" src="assets/imgs/portfol6.png" alt="" />
          </div>
          <h3>더 시그니처 골프 스위트</h3>
          <p>골퍼와 레슨프로 매칭 예약 앱</p>
        </div>
        <div class="portfolio-card">
          <div class="img-wrap">
            <img loading="lazy" src="assets/imgs/portfol7.png" alt="" />
          </div>
          <h3>미션고</h3>
          <p>미션 참여를 통해 소통하는 앱</p>
        </div>
        <div class="portfolio-card">
          <div class="img-wrap">
            <img loading="lazy" src="assets/imgs/portfol8.png" alt="" />
          </div>
          <h3>미션고</h3>
          <p>미션 참여를 통해 소통하는 앱</p>
        </div>
        <div class="portfolio-card">
          <div class="img-wrap">
            <img loading="lazy" src="assets/imgs/portfol9.png" alt="" />
          </div>
          <h3>미션고</h3>
          <p>미션 참여를 통해 소통하는 앱</p>
        </div>
        <div class="portfolio-card">
          <div class="img-wrap">
            <img loading="lazy" src="assets/imgs/portfol10.png" alt="" />
          </div>
          <h3>미션고</h3>
          <p>미션 참여를 통해 소통하는 앱</p>
        </div>
        <div class="portfolio-card">
          <div class="img-wrap">
            <img loading="lazy" src="assets/imgs/portfol11.png" alt="" />
          </div>
          <h3>미션고</h3>
          <p>미션 참여를 통해 소통하는 앱</p>
        </div>
        <div class="portfolio-card">
          <div class="img-wrap">
            <img loading="lazy" src="assets/imgs/portfol12.png" alt="" />
          </div>
          <h3>미션고</h3>
          <p>미션 참여를 통해 소통하는 앱</p>
        </div>
      </div>

    -->

      <div class="portfolio-more"
        style="margin-bottom: 100px">
        <button (click)="goPortfolio()">포트폴리오 더보기</button>
      </div>

      <p class="more-portfolio">
        <span>
          고객님의 소중한 <span class="b-txt">아이디어</span>를 바탕으로<span class="break"></span>
          기획부터 디자인, 개발, 검수, 마케팅까지<br />
          믿고 맡길 수 있는 스타트앱 ALL-IN-ONE 서비스
        </span>
        <button (click)="goInquiry()">무료 견적 신청</button>
      </p>
    </div>

    <div class="store-wrap"
      id="sToreArea">
      <h3>
        <p><span class="b-txt">개발 비용</span>이 부담이신가요?</p>
        <p>
          <span class="b-txt">개발 완료</span>까지 걸리는 시간이 부담이신가요?
        </p>
        망설이고 계시다면
      </h3>

      <div class="store-box">
        <h3>스타터팩 상품</h3>
        <p>
          쉽고(eas<span class="b-txt">s</span>y), 빠르고(fa<span class="b-txt">s</span>t), 합리적인(rea<span
            class="b-txt">s</span>onable)<span class="break"></span>
          가격에 만들어진 앱을 바로 구매하세요.
        </p>
        <div class="img-back-box">
          <img class="store1"
            src="assets/imgs/s-tore-back1.png"
            alt="스토어 배경 이미지" />
          <img class="store2"
            src="assets/imgs/s-tore-back2.png"
            alt="스토어 배경 이미지" />
          <img class="store3"
            src="assets/imgs/s-tore-back3.png"
            alt="스토어 배경 이미지" />
          <img class="store4"
            src="assets/imgs/s-tore-back4.png"
            alt="스토어 배경 이미지" />
          <img class="store5"
            src="assets/imgs/s-tore-back5.png"
            alt="스토어 배경 이미지" />
          <img class="store6"
            src="assets/imgs/s-tore-back6.png"
            alt="스토어 배경 이미지" />
          <img class="store7"
            src="assets/imgs/s-tore-back7.png"
            alt="스토어 배경 이미지" />
        </div>
        <button (click)="goSolutionHome()">둘러보기</button>
      </div>
    </div>

    <section class="insight_box">
      <h2>빛나는 우리의 통찰력</h2>
      <p>
        나날이 발전하는 트렌드에 우리는 새로운 기술과 비즈니스 기회를
        연구하며<span class="break"></span>
        디지털화 하는 방법에 대한 연구를 지속적으로 만들어나가고 있습니다.
      </p>

      <div class="button_wrap">
        <ul>
          <li>
            <button>
              <a href="https://blog.naver.com/kncurationkorea/222301543057"
                target="_blank">아이디어는 어떻게 앱이 될까?</a>
            </button>
          </li>
          <li>
            <button>
              <a href="https://blog.naver.com/kncurationkorea/222303937227"
                target="_blank">앱으로 어떻게 돈을 벌 수 있을까?</a>
            </button>
          </li>
          <li>
            <button>
              <a href="https://blog.naver.com/kncurationkorea/222376801467"
                target="_blank">좋은 앱 디자인, 어떻게 할까?</a>
            </button>
          </li>
        </ul>
        <a href="https://blog.naver.com/kncurationkorea"
          target="_blank">연구에 대해 알아보기 ></a>
      </div>
    </section>
  </section>
  <section class="bottom-line">
    <div class="bottom_box">
      <h2>스타트앱</h2>
      <p>당신의 성공은 여기서 시작됩니다.</p>
    </div>
  </section>

  <!-- ** 추가 ** -->
  <div class="home-fab-buttons only-mo-view">
    <button class="fab-btn tel-btn"
      type="button"
      (click)="call()">
      전화하기
    </button>

    <button (click)="inqPopup()"
      class="fab-btn"
      type="button">문의하기</button>
  </div>
  <!-- ** 추가 ** -->

  <footer-share id="footerWrap"></footer-share>
  <footer-share class="mobile-footer"></footer-share>
</main>

<img src="assets/imgs/img_ux.gif"
  alt=""
  class="loading" />
<img src="assets/imgs/img_ui.gif"
  alt=""
  class="loading" />
<img src="assets/imgs/img_develop.gif"
  alt=""
  class="loading" />
<img src="assets/imgs/img_marketing.gif"
  alt=""
  class="loading" />