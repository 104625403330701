<!-- <footer
  id="footer"
  [class.white]="
    path == '/inquiry' ||
    path == '/portfolio-detail' ||
    path == '/portfolio-hanra' ||
    path == '/portfolio' ||
    path == '/store-detail' ||
    path == '/solution-payment' ||
    path == '/solution-payment-completed' ||
    path == '/solution-guide' ||
    path == '/solution-product-category' ||
    path == '/design-template' ||
    path == '/solution-home' ||
    path == '/'
  "
> -->
<footer id="footer">
  <div class="footer-top"
    *ngIf="planSwitch || designSwitch || developSwitch || marketingSwitch">
    <div class="footer-inner">
      <div class="btns-wrap">
        <div class="btns-inner">
          <button class="prev-btn"
            *ngIf="designSwitch">
            <a (click)="goplan()"></a>UX기획&전략
          </button>
          <button class="prev-btn"
            *ngIf="developSwitch">
            <a (click)="godesign()"></a>UI 디자인
          </button>
          <button class="prev-btn"
            *ngIf="marketingSwitch">
            <a (click)="godevelopment()"></a>Android/iOS 개발
          </button>
          <button class="prev-btn"
            *ngIf="planSwitch">
            <a (click)="gomarketing()"></a>마케팅
          </button>
          <p>더 많은 프로세스를 만나보세요.</p>
          <button class="next-btn"
            *ngIf="marketingSwitch">
            <a (click)="goplan()"></a>UX기획&전략
          </button>
          <button class="next-btn"
            *ngIf="planSwitch">
            <a (click)="godesign()"></a>UI 디자인
          </button>
          <button class="next-btn"
            *ngIf="designSwitch">
            <a (click)="godevelopment()"></a>Android/iOS 개발
          </button>
          <button class="next-btn"
            *ngIf="developSwitch">
            <a (click)="gomarketing()"></a>마케팅
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="footer_wrap">
    <div class="bt-menu-wrap">
      <ul class="bt_menu">
        <li>
          <p>홈</p>
          <ul id="homeFooter">
            <li class="to-process">홈</li>
          </ul>
          <ul id="otherFooter">
            <li><a (click)="goHome()">홈</a></li>
          </ul>
        </li>
        <li>
          <p>업무 서비스</p>
          <ul class="menu-area">
            <li><a (click)="goplan()">UX 기획 & 전략</a></li>
            <li><a (click)="godesign()">UI 디자인</a></li>
            <li><a (click)="godevelopment()">Android/iOS 개발 </a></li>
            <li><a (click)="gomarketing()">마케팅</a></li>
          </ul>
        </li>
        <li class="tab-m">
          <p>기타</p>
          <ul>
            <!-- <li><a href="/simple-estimate">간편 견적 내기</a></li> -->
            <li>
              <a href="https://blog.naver.com/kncurationkorea"
                target="_blank">공식 블로그</a>
            </li>
          </ul>
        </li>
        <li>
          <p>소셜</p>
          <div class="sns_box">
            <ul>
              <li class="insta">
                <a href="https://www.instagram.com/startapp_kn/"
                  target="_blank">인스타그램</a>
              </li>
              <li class="fb">
                <a href="https://www.facebook.com/startapp.kn"
                  target="_blank">페이스북</a>
              </li>
            </ul>
          </div>
        </li>
        <!-- <li class="contact-m">
          <p>문의하기</p>

        </li> -->
      </ul>
      <div class="contact-area">
        <div class="btn-wrap">
          <button><a (click)="goInquiry()">무료 견적 신청</a></button>
          <button class="btn-down">
            <a (click)="save()">
              <span>회사소개서</span>
              <span class="icon"></span>
            </a>
          </button>
        </div>
        <ul class="contact-box pc">
          <li>이메일<span>&nbsp;&nbsp;support@startapp.co.kr</span></li>
          <li>전화상담<span>&nbsp;&nbsp;02-408-5020</span></li>
          <!-- <li class="add-list">
              주소
              <address>
                <span class="spacing">&nbsp;&nbsp;</span>서울특별시 성동구
                연무장5길 9-16 블루스톤타워 8층
              </address>
            </li> -->
          <!-- <li class="terms">
              <button (click)="terms('service')">이용약관</button>
              &nbsp;&nbsp;
            </li>
            <li class="terms center-btn">
              <button (click)="terms('privacy')">개인정보보호정책</button>
              &nbsp;&nbsp;
            </li> -->
          <!-- <li class="no-margin">
              개인정보보호책임자<span>&nbsp;&nbsp;고현</span>
            </li> -->
          <!-- <li class="sns_box">
              <ul>
                <li class="fb">
                  <a
                    href="https://www.facebook.com/startapp.kn"
                    target="_blank"
                    >페이스북</a
                  >
                </li> -->
          <!-- <li class="kakao"><a href="#">카카오톡</a></li> -->
          <!-- <li class="insta">
                  <a
                    href="https://www.instagram.com/startapp_kn/"
                    target="_blank"
                    >인스타그램</a
                  >
                </li>
              </ul>
            </li> -->
        </ul>
      </div>
    </div>

    <div class="top_btn">
      <!-- <div class="sns_box">
        <ul>
          <li class="fb">
            <a href="https://www.facebook.com/startapp.kn" target="_blank"
              >페이스북</a
            >
          </li>
          <li class="kakao"><a href="#">카카오톡</a></li>
          <li class="insta">
            <a href="https://www.instagram.com/startapp_kn/" target="_blank"
              >인스타그램</a
            >
          </li>
        </ul>
      </div> -->
      <button class="go-top">위로가기</button>
    </div>
    <div class="com-info">
      <div class="info">
        <span>주식회사 스타트앱</span>
        <div class="contact-box mo">
          <span>이메일 <span>support@startapp.co.kr</span></span>
          <span>전화상담 <span>02-408-5020</span></span>
        </div>
        <span>대표 <span>고현</span></span>
        <span>사업자등록번호 <span>577-86-02837</span></span>
        <span class="address">주소
          <address>
            서울특별시 강남구 역삼로 512, 5층 - 651(대치동, 인테크빌딩)
          </address>
        </span>
        <ul class="contact-box">
          <li class="terms">
            <button (click)="terms('service')">이용약관</button>
          </li>
          <li class="terms center-btn">
            <button (click)="terms('privacy')">개인정보보호정책</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="sns_box mo">
      <ul>
        <li class="insta">
          <a href="https://www.instagram.com/startapp_kn/"
            target="_blank"></a>
        </li>
        <li class="fb">
          <a href="https://www.facebook.com/startapp.kn"
            target="_blank"></a>
        </li>
      </ul>
    </div>
    <span class="copy-text">Copyright ⓒ 2022 스타트앱 All rights reserved.</span>
  </div>
</footer>